<template>
	<div class="PurchaseBuy">
		<w-navTab titleText="立即购买"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="container-top">
				<div class="header">
					<div class="header-top">
						<div class="header-top-img">
							<img :src="detail.image_cover" alt="" />
						</div>
						<div class="header-top-content">
							<div class="header-content-title">
								{{detail.name}}
							</div>
							<div class="header-content-quantity">
								<div class="header-quantity-text">
									<span>￥</span>{{detail.price}}/件
								</div>
								<div class="header-quantity-button">
									<van-stepper v-model="amount" />
								</div>
							</div>
						</div>
					</div>
					<div class="header-bottom">
						<div class="header-bottom-total">
							<div class="header-total-text">总计：</div>
							<div class="header-total-price">
								<span>￥</span>{{(parseInt((detail.price * amount)*1000)/1000).toFixed(2)}}
							</div>
						</div>
						<div class="header-bottom-text">
							(价格仅供参考，请以实际电话沟通为准)
						</div>
					</div>
				</div>
				<div class="body">
					<div class="body-content">
						<div class="body-content-label">收货人</div>
						<div class="body-content-input">
							<input type="text" v-model="recipient" placeholder="请输入收货人" />
						</div>
					</div>
					<div class="body-content">
						<div class="body-content-label">手机号码</div>
						<div class="body-content-input">
							<input type="text" v-model="phoneNumber" maxlength="11" placeholder="请输入手机号码" />
						</div>
					</div>
					<div class="body-content" @click.stop="show = !show">
						<div class="body-content-label">所在地区</div>
						<div class="body-content-input">
							<input type="text" v-model="location" placeholder="请选择所在地区" readonly />
							<van-icon name="arrow" />
						</div>
					</div>
					<div class="body-content">
						<div class="body-content-label">详细地址</div>
						<div class="body-content-input">
							<textarea v-model="address" placeholder="请输入详细地址"></textarea>
						</div>
					</div>
					<div class="body-content">
						<div class="body-content-label">备注</div>
						<div class="body-content-input">
							<textarea v-model="remark" placeholder="请输入备注"></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="container-middle">
				信息提交后五个工作日内会有专人联系对接，请<span>注意接听来电</span>
			</div>
			<div class="container-footer" @click="onSubmit">
				<div class="container-footer-text">确认提交</div>
			</div>
		</div>
		<van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
			<van-area title="标题" :area-list="areaList" :value="value" @cancel="show = !show"
				@confirm="onClickConfirm" />
		</van-popup>
	</div>
</template>

<script>
	import Purchase from '@/api/purchase';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';
	import {
		areaList
	} from '@vant/area-data';
	import ValidatorVendor from '@/vendor/validator';

	export default {
		name: 'PurchaseBuy',
		data() {
			return {
				loadingPage: true,
				detail: '',
				productsId: '',
				amount: 1,
				recipient: '',
				phoneNumber: '',
				location: '',
				address: '',
				remark: '',
				show: false,
				value: '',
				areaList
			}
		},
		created() {
			this.productsId = this.$route.params.id
			this.getDetail()
			this.validator = new ValidatorVendor({
				rules: {
					recipient: {
						required: true
					},
					phone_number: {
						required: true,
						mobile: true
					},
					location: {
						required: true
					},
					address: {
						required: true
					}
				},
				messages: {
					recipient: {
						required: '姓名不能为空'
					},
					phone_number: {
						mobile: '手机号格式错误',
						required: '手机号不能为空'
					},
					location: {
						required: '请选择所在地区'
					},
					address: {
						required: '详细地址不能为空'
					}
				}
			});
		},
		methods: {
			onClickConfirm(key) {
				this.value = key[2].code
				this.show = !this.show
				this.location = key[0].name + '-' + key[1].name + '-' + key[2].name
			},
			getDetail() {
				Purchase.productsDetail({
						products_id: this.productsId
					})
					.then(result => {
						this.detail = result.data
						this.loadingPage = false
					})
					.catch(error => {
						console.log(error)
					})
			},
			onSubmit() {

				let params = {
					products_id: this.productsId,
					amount: this.amount,
					recipient: this.recipient,
					phone_number: this.phoneNumber,
					location: this.location,
					address: this.address,
					remark: this.remark
				}

				if (!this.validator.checkData(params)) {
					return;
				}
				
				VantVendor.Dialog.confirm({
						title: '提示',
						message: '您确认提交吗？',
					})
					.then(() => {
						Purchase.apply(params)
							.then(result => {
								VantVendor.Toast.success('提交成功')
								setTimeout(()=>{
									this.$router.go(-1)
								},1000)
							})
							.catch(error => {
								VantVendor.Toast(error.msg)
							})
					})

			}
		},
		components: {
			VantVendor,
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.PurchaseBuy {
		width: 100%;
		min-height: 100vh;
		background: #F2F2F2;
		padding-bottom: 66px;
		box-sizing: border-box;

		.container {

			.container-top {
				background: #FFF;
				padding: 15px 16px 0;
				box-sizing: border-box;

				.header {
					padding: 12px 12px 10px;
					border-radius: 8px;
					background: #F8F8F8;
					box-sizing: border-box;

					.header-top {
						padding-bottom: 12px;
						border-bottom: 1px solid #EEE;
						box-sizing: border-box;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.header-top-img {
							width: 88px;
							height: 88px;
							border-radius: 2px;
							border: 1px solid #E6E6E6;

							img {
								width: 100%;
								height: 100%;
								border-radius: 2px;
								object-fit: cover;
							}
						}

						.header-top-content {
							flex: 1;
							margin-left: 17px;

							.header-content-title {
								color: #333;
								font-size: 16px;
								line-height: 24px;
								height: 48px;
							}

							.header-content-quantity {
								margin-top: 8px;
								display: flex;
								justify-content: space-between;
								align-items: center;

								.header-quantity-text {
									color: #333;
									font-size: 22px;
									line-height: 32px;

									span {
										font-size: 12px;
										line-height: 18px;
									}
								}

								.header-quantity-button {}
							}
						}
					}

					.header-bottom {
						margin-top: 8px;

						.header-bottom-total {
							display: flex;
							align-items: center;
							justify-content: flex-end;

							.header-total-text {
								color: #999;
								font-size: 14px;
								line-height: 21px;
							}

							.header-total-price {
								color: #FF6969;
								font-size: 24px;
								font-weight: bold;
								line-height: 36px;

								span {
									font-size: 12px;
									font-weight: 400;
									line-height: 18px;
								}
							}
						}

						.header-bottom-text {
							color: #999;
							font-size: 12px;
							line-height: 18px;
							text-align: right;
						}
					}
				}

				.body {
					padding: 12px 0 14px;
					box-sizing: border-box;

					.body-content {
						display: flex;
						padding: 12px 0;
						border-bottom: 1px solid #F4F4F4;
						box-sizing: border-box;

						&.body-content:last-child {
							border: 0;
						}

						.body-content-label {
							width: 88px;
							min-width: 88px;
							color: #999;
							font-size: 16px;
							line-height: 24px;
						}

						.body-content-input {
							flex: 1;
							color: #333;
							font-size: 16px;
							line-height: 24px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							input {
								width: 100%;
								border: 0;
								background: #FFF;
							}

							textarea {
								width: 100%;
								border: 0;
							}
						}
					}
				}
			}

			.container-middle {
				width: 212px;
				min-width: 212px;
				color: #999;
				font-size: 14px;
				line-height: 21px;
				margin: 14px auto 0;
				text-align: center;
				padding-bottom: 20px;
				box-sizing: border-box;

				span {
					color: #FF6969;
				}
			}

			.container-footer {
				position: fixed;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 66px;
				padding: 12px 16px;
				background: #FFF;
				box-sizing: border-box;

				.container-footer-text {
					padding: 9px 0;
					color: #FFF;
					text-align: center;
					font-size: 16px;
					font-weight: bold;
					border-radius: 70px;
					background: #37F;
					box-sizing: border-box;
				}
			}
		}
	}
</style>